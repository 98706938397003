import 'vue-tel-input/vue-tel-input.css'

import VueTelInput from 'vue-tel-input'

export const registerTelInput = (app: any) => {
  app.use(VueTelInput, {
    autoDefaultCountry: false,
    inputOptions: {
      placeholder: '',
    },
    mode: 'international',
  })
}
